import { FAQ } from '~/types/data-array'

const MvpFAQ: FAQ[] = [
  {
    question: 'What is product discovery?',
    answer:
      'Product discovery is the first stage of solution development. This stage intends to collect information about the project to define its vision, aims, and scope.',
  },
  {
    question: 'How much time does the product discovery take?',
    answer:
      'The time depends on many factors. Usually, this stage lasts from several weeks to several months. This time can vary depending on the complexity of the project you need to discover.',
  },
  {
    question: 'What problems can solve the product discovery service?',
    answer:
      'The project discovery sessions help you validate your business ideas and assess the value you can bring to customers. This saves you from unnecessary costs and effort spent on building extra features.',
  },
  {
    question: 'What is the price for product discovery services?',
    answer:
      'The charge can vary depending on the complexity of your future solution, the region of your contractor, the hourly rates of the development team, etc. In Ukraine, the average price is about $5,000-$20,000.',
  },
]

export default MvpFAQ
