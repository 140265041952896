import { ExpertiseSectionMock } from '~/types/mock'

const ProductDiscoveryWhyNeed: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_45',
    title: 'Avoid unnecessary risks',
  },
  {
    icon: 'spriteIcon_44',
    title: 'Minimize development cost',
  },
  {
    icon: 'spriteIcon_47',
    title: 'Build a product that fits the market',
  },
  {
    icon: 'spriteIcon_49',
    title: 'Stand out from the competition',
  },
  {
    icon: 'spriteIcon_51',
    title: 'Make informed decisions',
  },
  {
    icon: 'spriteIcon_48',
    title: 'Know what features to include in MVP',
  },
  {
    icon: 'spriteIcon_50',
    title: 'Minimize the possibility of failure',
  },
  {
    icon: 'spriteIcon_46',
    title: 'Get expert advice and avoid rework',
  },
  {
    icon: 'spriteIcon_43',
    title: 'Address real users’ needs',
  },
  {
    icon: 'spriteIcon_52',
    title: 'Know the exact budget needed',
  },
]

export default ProductDiscoveryWhyNeed
