// extracted by mini-css-extract-plugin
export var productDiscoverTestimonialSection = "w_c1";
export var productDiscoveryAchievementsSection = "w_dc";
export var productDiscoveryBenefitsSection = "w_c0";
export var productDiscoveryCaseSection = "w_c7";
export var productDiscoveryClientSection = "w_dd";
export var productDiscoveryIndustriesSection = "w_cZ";
export var productDiscoveryOverviewSection = "w_c5";
export var productDiscoveryProcessSection = "w_c4";
export var productDiscoveryResultsSection = "w_c8";
export var productDiscoveryRolesSection = "w_c6";
export var productDiscoveryServicesSection = "w_cY";
export var productDiscoveryStagesSection = "w_c2";
export var productDiscoverySuccessStoriesSection = "w_c9";
export var productDiscoveryWhyChooseSection = "w_db";
export var productDiscroveryImportanceSection = "w_c3";