import { graphql, useStaticQuery } from 'gatsby'

import { PhotosQuery } from '~/types/graphql'

const useProductDiscoveryStaticQuery = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      portfolioPMK: file(
        relativePath: { eq: "homepage/discover-works-portfolio-2.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      tailoredOne: file(
        relativePath: { eq: "company-photo/company-photo-45.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      tailoredTwo: file(
        relativePath: { eq: "company-photo/company-photo-46.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      tailoredThree: file(
        relativePath: { eq: "company-photo/company-photo-15.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      tailoredFour: file(
        relativePath: { eq: "company-photo/company-photo-26.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      clientImpact: file(
        relativePath: { eq: "avatars/clients/large-photo/client-impact.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      fullVideoImage: file(
        relativePath: { eq: "services/video/project-discovery-video.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      quoteCodicaCEOPhoto: file(relativePath: { eq: "avatars/CEO.png" }) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  return query
}

export default useProductDiscoveryStaticQuery
