// extracted by mini-css-extract-plugin
export var productDiscoverySession__card = "dn_Bm";
export var productDiscoverySession__cardFour = "dn_Br";
export var productDiscoverySession__cardOne = "dn_Bn";
export var productDiscoverySession__cardThree = "dn_Bq";
export var productDiscoverySession__cardTwo = "dn_Bp";
export var productDiscoverySession__cardWrapper = "dn_Bl";
export var productDiscoverySession__description = "dn_Bv";
export var productDiscoverySession__header = "dn_Bk";
export var productDiscoverySession__image = "dn_Bw";
export var productDiscoverySession__info = "dn_Bs";
export var productDiscoverySession__title = "dn_Bt";