import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import { Quote } from '~/types/data-array'
import { cx } from '~/utils'

import * as containerStyles from './ServicesProductDiscoverySession.module.scss'

interface ServicesProductDiscoverySessionCardProps {
  className: string
  title: string
  description: Quote[]
  image: IGatsbyImageData | undefined
}

const ServicesProductDiscoverySessionCard = ({
  className,
  image,
  title,
  description,
}: ServicesProductDiscoverySessionCardProps) => (
  <div className={containerStyles.productDiscoverySession__cardWrapper}>
    <div
      className={cx(containerStyles.productDiscoverySession__card, className)}
    >
      <div className={containerStyles.productDiscoverySession__info}>
        <div className={containerStyles.productDiscoverySession__title}>
          {title}
        </div>
        {description.map(({ text }) => (
          <div
            key={text}
            className={containerStyles.productDiscoverySession__description}
          >
            {text}
          </div>
        ))}
      </div>
      <div className={containerStyles.productDiscoverySession__image}>
        <GatsbyImage
          image={image as IGatsbyImageData}
          alt={`${title} | Codica`}
          title={title}
          loading="lazy"
        />
      </div>
    </div>
  </div>
)

export default ServicesProductDiscoverySessionCard
