import { ExpertiseSectionMock } from '~/types/mock'

const ProjectDiscoveryResults: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_53',
    title: 'Clickable prototypes',
    description:
      'One of the product discovery methods is in-depth UX research. As a result, we deliver a set of clickable prototypes for the main pages that represent real application user flow and functionality.',
  },
  {
    icon: 'spriteIcon_35',
    title: 'Time and cost estimation',
    description:
      'This is one of the main project discovery deliverables; you will receive a detailed estimate divided by features and services specified in hours, including the price for the whole project development process.',
  },
  {
    icon: 'spriteIcon_54',
    title: 'List of priority features',
    description:
      'Our team will analyze your business goals. Applying our domain expertise, we suggest a set of recommended functionality to launch your product and create a list of prioritized features.',
  },
  {
    icon: 'spriteIcon_56',
    title: 'Recommendations on team composition',
    description:
      'Based on the project time estimate and release goals. We will offer suitable team composition: backend, frontend developers, project managers, business analysts, UX/UI designers, QA, and DevOps engineers.',
  },
  {
    icon: 'spriteIcon_1',
    title: 'Technical stack and architecture',
    description:
      'Our architects will analyze your project requirements and will offer the best suitable tech stack to meet your product’s potential future needs fully.',
  },
  {
    icon: 'spriteIcon_55',
    title: 'Product specification',
    description:
      'This is a high-level structured document that outlines key product requirements and lists other important details which are crucial for building a successful project.',
  },
]

export default ProjectDiscoveryResults
