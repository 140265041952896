import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

import { PhotosQuery } from '~/types/graphql'
import { fileToImageLikeData } from '~/utils'

import * as containerStyles from './ServicesProductDiscoverySession.module.scss'
import ServicesProductDiscoverySessionCard from './ServicesProductDiscoverySessionCard'

const ServicesProductDiscoverySession = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      imageOne: file(
        relativePath: { eq: "services/product-discovery-sessions-1.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      imageTwo: file(
        relativePath: { eq: "services/product-discovery-sessions-2.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      imageThree: file(
        relativePath: { eq: "services/product-discovery-sessions-3.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      imageFour: file(
        relativePath: { eq: "services/product-discovery-sessions-4.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  const imageOne = getImage(fileToImageLikeData(query.imageOne))
  const imageTwo = getImage(fileToImageLikeData(query.imageTwo))
  const imageThree = getImage(fileToImageLikeData(query.imageThree))
  const imageFour = getImage(fileToImageLikeData(query.imageFour))

  return (
    <div className="container container-md">
      <div className="row">
        <div className="col-12">
          <div className={containerStyles.productDiscoverySession__header}>
            From the customer side:
          </div>
        </div>

        <div className="col-12">
          <ServicesProductDiscoverySessionCard
            className={containerStyles.productDiscoverySession__cardOne}
            image={imageOne}
            title="Stakeholders"
            description={[
              {
                text: 'During the product discovery stage, stakeholders represent the client’s team and deliver their business idea to a software company. They provide as much information about the future product as possible. This will help Codica experts to analyze the idea, evaluate risks and suggest the best solution.',
              },
              {
                text: 'Here’s an example of the details we may need from your side: the product goals, its target audience, what user needs it should cover, your expectations and requirements within a project, etc.',
              },
              {
                text: 'If you have ready requirements documentation, target audience research, wireframes, or prototypes - please provide them as well. The more information we have from the start, the quicker we can evaluate your idea and suggest solutions and cost estimates.',
              },
            ]}
          />
        </div>

        <div className="col-12">
          <div className={containerStyles.productDiscoverySession__header}>
            From the Codica team:
          </div>
        </div>

        <div className="col-12">
          <ServicesProductDiscoverySessionCard
            className={containerStyles.productDiscoverySession__cardTwo}
            image={imageTwo}
            title="Business Analysts / Product Managers"
            description={[
              {
                text: 'Product Managers collect, analyze and structure the input data from clients.',
              },
              {
                text: 'They apply their domain expertise to research and help evaluate the business idea. Thus, they provide a list of must-have functionality for the primary product. Next, they list epics and user stories and form the project scope that will be transformed into an estimate for the client.',
              },
              {
                text: 'Product Managers also facilitate the discussion process between the client representatives and Codica team members. PMs also make sure the goals of the project discovery phase are met.',
              },
            ]}
          />
        </div>

        <div className="col-12">
          <ServicesProductDiscoverySessionCard
            className={containerStyles.productDiscoverySession__cardThree}
            image={imageThree}
            title="UX/UI Designers"
            description={[
              {
                text: 'Web designers elaborate and discuss the product idea and what user problem it should solve.',
              },
              {
                text: 'They conduct thorough UX research and analyze the target audience, existing competitors, and their products.',
              },
              {
                text: 'This information will allow UX/UI designers to build wireframes and clickable prototypes to show user flow and features of your future product.',
              },
            ]}
          />
        </div>

        <div className="col-12">
          <ServicesProductDiscoverySessionCard
            className={containerStyles.productDiscoverySession__cardFour}
            image={imageFour}
            title="Software Architects"
            description={[
              {
                text: 'Architects are responsible for technical solutions.',
              },
              {
                text: 'They analyze the existing documentation or requirements, the product functionality, and possible future load. Software architects also make sure that the designed business logic is feasible within the development process.',
              },
              {
                text: 'Finally, technical experts offer the best architectural solution and technical stack to deliver the product quickly.',
              },
            ]}
          />
        </div>
      </div>
    </div>
  )
}

export default ServicesProductDiscoverySession
