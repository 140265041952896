import { getImage, StaticImage } from 'gatsby-plugin-image'

import processLine from '~/assets/images/services/process-line-6.svg?file'
import CompanyWhyHireBlock from '~/components/blocks/CompanyWhyHireBlock'
import DevelopmentProcess from '~/components/blocks/DevelopmentProcess'
import FaqSection from '~/components/blocks/FAQ'
import HeadSection from '~/components/blocks/HeadSection'
import Testimonial from '~/components/blocks/TestimonialPost'
import CallToAction from '~/components/call-to-actions/CallToAction'
import ServicesIconSmallCard from '~/components/cards/ServicesIconSmallCard'
import ServicesIndustriesCard from '~/components/cards/ServicesIndustriesCard'
import ClientVideoQuotes from '~/components/items/ClientVideoQuotes'
import DiscoverWorksItem from '~/components/items/DiscoverWorksItem'
import MainLayout from '~/components/layouts/MainLayout'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import PrimeSectionServices from '~/components/sections/PrimeSectionServices'
import SuccessStoriesSliderSection from '~/components/sections/SuccessStoriesSliderSection'
import LazyHydrate from '~/components/shared/LazyHydrate'
import StickyNavigation from '~/components/sticky/StickyNavigation'
import Anchors from '~/mock/services/product-discovery/Anchors'
import ProductDiscoveryFAQ from '~/mock/services/product-discovery/ProductDiscoveryFAQ'
import ProductDiscoveryWhyChoose from '~/mock/services/product-discovery/ProductDiscoveryWhyChoose'
import ProductDiscoveryWhyNeed from '~/mock/services/product-discovery/ProductDiscoveryWhyNeed'
import ProductIndustries from '~/mock/services/product-discovery/ProductIndustries'
import ProductWhatIs from '~/mock/services/product-discovery/ProductWhatIs'
import ProjectDiscoveryProcess from '~/mock/services/product-discovery/ProjectDiscoveryProcess'
import ProjectDiscoveryResults from '~/mock/services/product-discovery/ProjectDiscoveryResults'
import { cx, fileToImageLikeData } from '~/utils'
import AwardsServicesSection from '~/views/Services/components/AwardsServicesSection'
import ServicesFullVideoSection from '~/views/Services/components/ServicesFullVideoSection'
import ServicesProductDiscoverySession from '~/views/Services/components/ServicesProductDiscoverySession'
import ServicesSectionBeforeTestimonials from '~/views/Services/components/ServicesSectionBeforeTestimonials'
import ServicesSuccessfulSection from '~/views/Services/components/ServicesSuccessfulSection'
import useProductDiscoveryStaticQuery from '~/views/Services/ProductDiscovery/useProductDiscoveryStaticQuery'

import * as containerStyles from './ProductDiscovery.module.scss'

const clientName = 'David Watkins'

const ProductDiscovery = () => {
  const query = useProductDiscoveryStaticQuery()

  const portfolioPMK = getImage(fileToImageLikeData(query.portfolioPMK))
  const tailoredOne = getImage(fileToImageLikeData(query.tailoredOne))
  const tailoredTwo = getImage(fileToImageLikeData(query.tailoredTwo))
  const tailoredThree = getImage(fileToImageLikeData(query.tailoredThree))
  const tailoredFour = getImage(fileToImageLikeData(query.tailoredFour))
  const clientImpact = getImage(fileToImageLikeData(query.clientImpact))
  const fullVideoImage = getImage(fileToImageLikeData(query.fullVideoImage))
  const quoteCodicaCEOPhoto = getImage(
    fileToImageLikeData(query.quoteCodicaCEOPhoto),
  )

  return (
    <MainLayout scrollTop>
      <PrimeSectionServices
        titleTop="Product Discovery"
        title="Services"
        description="Make your product exceptional and avoid risks from the start."
        breadcrumbSecondLevel="Services"
        breadcrumbSecondLevelPath="/services/"
        breadcrumbCurrent="Product Discovery Services"
        breadcrumbCurrentPath="/services/product-discovery/"
      />

      <StickyNavigation anchors={Anchors} />

      <section
        id="definition"
        className={containerStyles.productDiscoveryServicesSection}
      >
        <HeadSection
          position="center"
          title="What is product discovery?"
          description="The product discovery is the first step of custom software development. At this phase, we perform complex research and analysis of the client’s idea to develop a solution that meets all their needs. The discovery sessions reveal all the software’s strong and weak points at the earliest stage. With product discovery services, our clients will be able to reduce risks, increase efficiency, minimize development costs, and avoid rework."
          descriptionLarge
        />
        <ServicesIconSmallCard isBorder dataContent={ProductWhatIs} />
      </section>

      <section
        className={containerStyles.productDiscoveryIndustriesSection}
        id="industries"
      >
        <HeadSection
          position="center"
          title="We bring expertise across different industries"
          description="During our work in software development for various industries, we have accumulated vast experience. We are glad to share it with our clients. Our experts consult clients in every product development phase, keeping all processes transparent. So, the clients receive the product they expect to get."
          descriptionLarge
        />
        <ServicesIndustriesCard dataContent={ProductIndustries} />
      </section>

      <section
        id="benefits"
        className={containerStyles.productDiscoveryBenefitsSection}
      >
        <HeadSection
          position="center"
          title="A strong foundation for your future product"
          description="Dealing with numerous startups we defined how important it is to create a solution that faces the real needs of the final users. Thus, during the product discovery stage, our priority is to reveal the unique value chain that the platform delivers."
          titleLarge
          descriptionLarge
        />
        <ServicesSectionBeforeTestimonials
          imageOne={tailoredOne}
          imageTwo={tailoredTwo}
          content={[
            {
              text: 'To achieve that, our business analysts define a bunch of features that aim for the specific and direct needs of your customers. Then, experts transform these features in a UX prototype and specification.',
            },
            {
              text: 'For a software project, the discovery process gives a good overview of the functionality. Also, it enables us to adjust the estimation to a specific timeframe or budget in case there are particular measurements.',
            },
            {
              text: 'Therefore, after product discovery sessions with the Codica team, you will be fully armed on your way to building a successful product.',
            },
            {
              text: 'After the project discovery phase is complete, the next step is building a minimum viable product (MVP), which will help test your idea with real users.',
            },
          ]}
        />
      </section>

      <section className={containerStyles.productDiscoverTestimonialSection}>
        <Testimonial
          image={quoteCodicaCEOPhoto}
          name="Dmytro"
          position="CEO & Founder at Codica"
          quote={[
            {
              text: '“They say that a product well started is a product half built. With the product discovery phase, we will help you get your project right from the start, validate your business idea and avoid failure.',
            },
            {
              text: 'Our expert team will analyze your idea and the problem you want to solve with your product and define the most efficient and cost-effective way to build it. Thanks to this approach, you will be able to make informed decisions right from the start and thus minimize risks.”',
            },
          ]}
        />
      </section>

      <section
        id="stages"
        className={containerStyles.productDiscoveryStagesSection}
      >
        <HeadSection
          position="center"
          title="What happens during the product discovery stage with Codica?"
          description="The product discovery stage consists of several sessions (on-site or remote), during which our team will deeply dive into your business idea and the users’ problem you want to solve. The number of sessions varies depending on the project’s complexity. Here’s what we will do during and after product discovery sessions:"
          titleLarge
          descriptionLarge
        />
        <ServicesSuccessfulSection
          imageOne={tailoredThree}
          imageTwo={tailoredFour}
          list={[
            {
              item: 'Explore the subject area and business idea',
            },
            {
              item: 'Analyze the problem our client wants to solve',
            },
            {
              item: 'Provide project cost and time estimate',
            },
            {
              item: 'Discuss expected challenges and suggest high-level solutions',
            },
            {
              item: 'Define priorities and identify bottlenecks',
            },
            {
              item: 'Suggest the initial product features and form project scope',
            },
            {
              item: 'Define the best technology stack',
            },
            {
              item: 'Perform UX research and create prototypes',
            },
            {
              item: 'Form project documentation',
            },
          ]}
        />
      </section>

      <section
        id="importance"
        className={containerStyles.productDiscroveryImportanceSection}
      >
        <HeadSection
          position="center"
          title="Why does your project need product discovery service?"
          description="Our experience shows that the project discovery approach works best for entrepreneurs who only have an idea of a product and want to:"
          titleLarge
          descriptionLarge
        />
        <ServicesIconSmallCard isBorder dataContent={ProductDiscoveryWhyNeed} />
      </section>

      <CallToAction
        title="Do you have a product idea?"
        subtitle="We will help you validate it and make informed decisions."
        classNameForGA="servicesCTA_1"
        linkName="Talk to an expert"
        link="/contacts/"
      />

      <section
        id="process"
        className={cx(
          containerStyles.productDiscoveryProcessSection,
          'processProductDiscoverySection',
        )}
      >
        <HeadSection
          position="center"
          title="Our product discovery process"
          description="The knowledge and insights arising during our expert product discovery sessions will assist you in ensuring consistency and the high quality of your software product."
          descriptionLarge
        />
        <DevelopmentProcess
          dataImage={processLine}
          dataContent={ProjectDiscoveryProcess}
        />
      </section>

      <section className={containerStyles.productDiscoveryOverviewSection}>
        <HeadSection
          position="center"
          title="Product discovery process overview"
          description="Any product idea is a hypothesis worth testing before investing in full-fledged development. We treat every hypothesis with due care so that you get a product your customers love."
          descriptionLarge
        />
        <LazyHydrate whenVisible>
          <ServicesFullVideoSection
            videoLink="https://www.youtube.com/embed/cab4ViH0_z0"
            videoImage={fullVideoImage}
            videoTitle="Product discovery process overview | Codica"
          />
        </LazyHydrate>
      </section>

      <section
        id="roles"
        className={containerStyles.productDiscoveryRolesSection}
      >
        <HeadSection
          position="center"
          title="Who is involved in product discovery sessions?"
          description="To achieve the goals of the project discovery phase, both the Codica team and customer representatives take part in the sessions. Active involvement from the client side is essential to success. Below we have listed the main product discovery roles."
          descriptionLarge
        />
        <ServicesProductDiscoverySession />
      </section>

      <section className={containerStyles.productDiscoveryCaseSection}>
        <HeadSection position="center" title="Featured project" />
        <DiscoverWorksItem
          key={2}
          gradient="gradientPmk"
          image={portfolioPMK}
          header="Online Service Marketplace"
          title="PlanMyKids is a service marketplace where parents can plan and book kids’ activities nearby. The platform offers a simple solution that connects parents with after-school programs and camps."
          resultsRight="Delivered a scalable MVP on time and below budget."
          resultsLeft="Ensured great user experience on both desktop and smartphone with a PWA."
          link="kids-activities-service-marketplace"
          review={[
            '“Working with the Codica team has been a great experience. All the value we thought they would provide during the selection process was met during the delivery.',
            <div key="featuredProjectKeyOne" style={{ paddingTop: 10 }} />,
            'Our team spent a good time working with a parent focus group to narrow down our MVP features which Codica brought to life through prototypes. When we got too ambitious with features, Codica team helped ground us with reminders of our MVP goals.',
            <div key="featuredProjectKeyTwo" style={{ paddingTop: 10 }} />,
            'In the end Codica delivered our marketplace MVP under budget, which for a bootstrapped company was important.”',
          ]}
          clientPhoto={
            <StaticImage
              src="../../../assets/images/avatars/clients/client-pmk.png"
              alt={`${clientName} | Codica`}
              title={clientName}
              width={50}
              height={50}
            />
          }
          clientName={clientName}
          clientPosition="Founder of PlanMyKids"
        />
      </section>

      <section
        id="results"
        className={containerStyles.productDiscoveryResultsSection}
      >
        <HeadSection
          position="center"
          title="Project discovery results: what deliverables you will get"
          description="Once the project discovery stage is over, you will receive a set of deliverables prepared by the Codica team. These artifacts will help you make the right decisions about your future product."
          descriptionLarge
        />
        <ServicesIconSmallCard dataContent={ProjectDiscoveryResults} />
      </section>

      <CallToAction
        title="How can you validate your idea and save costs?"
        subtitle="Start your project right with a product discovery stage."
        classNameForGA="servicesCTA_2"
        linkName="Talk to an expert"
        link="/contacts/"
      />

      <section
        id="cases"
        className={containerStyles.productDiscoverySuccessStoriesSection}
      >
        <HeadSection position="center" title="Success stories" />
        <SuccessStoriesSliderSection btnName="View all our works" />
      </section>

      <section className={containerStyles.productDiscoveryWhyChooseSection}>
        <HeadSection
          position="center"
          title="Why choose Codica for a new product discovery"
        />
        <CompanyWhyHireBlock dataContent={ProductDiscoveryWhyChoose} />
      </section>

      <section className={containerStyles.productDiscoveryAchievementsSection}>
        <HeadSection
          position="center"
          title="Our achievements"
          description="Our expertise in web development is confirmed by ratings and research companies."
        />
        <AwardsServicesSection />
      </section>

      <section className={containerStyles.productDiscoveryClientSection}>
        <HeadSection
          position="center"
          title="Our clients say"
          description="Genuine feedback from our clients is precious to us and inspires further progress. We invite you to explore the transformative business experiences of people who entrusted their projects to Codica."
          descriptionLarge
        />

        <div className="container container-md">
          <ClientVideoQuotes
            name="Richard Gunter"
            location="Israel"
            position="Founder of Impact Personal Training"
            image={clientImpact}
            quote={[
              {
                text: '“Coming from a gym environment, I had no idea how to build, create, and develop an app. Everything I needed to do was explained to me in layman’s terms. I was always up to date with where they were at on the project itself, which I found really helpful.”',
              },
            ]}
            link="fitness-progressive-web-application"
            videoLink="https://www.youtube.com/embed/r1tbx4MEmrY"
          />
        </div>
      </section>

      <section id="free-quote">
        <PrimeContactFormSection
          title="Looking for a reliable product discovery company?"
          widthTitle="700px"
          formInfoClassName="formInfo__services"
          customSalesInfo
          customSalesInfoStepFour="4. We will schedule sessions for the product discovery stage with our company’s experts, who will analyze your requirements and suggest the best ways to bring your idea to life."
        />
      </section>

      <section id="faq">
        <FaqSection title="FAQ" dataContent={ProductDiscoveryFAQ} />
      </section>
    </MainLayout>
  )
}

export default ProductDiscovery
