import { ExpertiseSectionMock } from '~/types/mock'

const ProductWhatIs: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_41',
    title: 'Minimize risks',
    description:
      'Statistics show that 90% of startups fail. In-depth research of the target audience, their pains, and their goals will help avoid building a failed product.',
  },
  {
    icon: 'spriteIcon_16',
    title: 'Save costs',
    description:
      'Our experts will help you define the most efficient way to solve your users’ needs, remove unnecessary features and keep development costs low.',
  },
  {
    icon: 'spriteIcon_42',
    title: 'Make informed decisions',
    description:
      'You will know the exact budget and timeframe you will need to build your initial product and can decide whether you want to proceed or not.',
  },
]

export default ProductWhatIs
