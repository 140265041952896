import { ProcessMock } from '~/types/mock'

const ProjectDiscoveryProcess: ProcessMock[] = [
  {
    num: '1',
    title: 'Assess initial idea',
    description:
      'This phase helps our experts align with the client’s aims and meet expectations.',
    classNames: 'cardOne',
  },
  {
    num: '2',
    title: 'Establish success metrics',
    description:
      'Here, we define the main pain points of the solution to prioritize features.',
    classNames: 'cardTwo',
  },
  {
    num: '3',
    title: 'Define the feature set',
    description:
      'We determine the main features and create a usability test of them.',
    classNames: 'cardThree',
  },
  {
    num: '4',
    title: 'Create a proof of concept',
    description:
      'At the proof of concept phase we start building the project’s architecture.',
    classNames: 'cardFour',
  },
  {
    num: '5',
    title: 'Build prototypes',
    description:
      'We transform the wireframes into user interface mockups to validate the market response.',
    classNames: 'cardFive',
  },
  {
    num: '6',
    title: 'Validate the project',
    description:
      'We use the functional prototype and guidelines to validate the product with real users.',
    classNames: 'cardSix',
  },
]

export default ProjectDiscoveryProcess
