import { BaseMock } from '~/types/mock'

const ProductDiscoveryWhyChoose: BaseMock[] = [
  {
    title: 'Expert recommendations',
    description:
      'We have already delivered numerous products for businesses like yours. So we will help clearly define your potential user problems and how your product can solve them.',
  },
  {
    title: 'Avoid unnecessary expenses',
    description:
      'Detailed analysis of your idea will help offer the smallest set of required features to test your idea with no considerable investment.',
  },
  {
    title: 'Speed up the development process',
    description:
      'Launching to market quickly is vital to outpace competitors. Proper planning will help avoid unnecessary actions and time wasted making features your users have no interest in.',
  },
  {
    title: 'Clear deliverables and no obligations',
    description:
      'We have a standard set of deliverables clearly defined at the beginning. These project discovery results, such as documents and design, can be used by any development team.',
  },
  {
    title: 'Enthusiastic team that cares',
    description:
      'We are a result-oriented team that applies deep domain expertise to advise on the best solution for your product goals. We will help you achieve them with a minimum budget.',
  },
  {
    title: 'Answers to all your questions',
    description:
      'Are you unsure how to proceed with your idea after the project discovery phase? We will provide you with the time and cost estimation. So you can make informed product decisions.',
  },
]

export default ProductDiscoveryWhyChoose
